<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        สรุปขายปลีกประจำวัน
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-date-picker
          class="form-control ant-date"
          :locale="locale"
          v-model:value="date"
          format="DD/MM/YYYY"
          inputReadOnly
        />
      </div>
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหาจาก เลขที่เอกสาร / รหัสผู้ซื้อ"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>

    <div class="card-body" style="padding-top: 3px; padding-bottom: 15px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ซื้อ</th>
              <th>ผู้ซื้อ</th>
              <th>วัน-เวลา</th>
              <th>ส่วนลด</th>
              <th>สุทธิ</th>
              <th style="text-align: center">reprint</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td class="fw-boldest">
                <a @click="goToEdit(item.id)">
                  <u>
                    {{
                      item.sales_no
                        ? item.sales_no
                        : item.point_of_sale_no
                        ? item.point_of_sale_no
                        : ""
                    }}
                  </u>
                </a>
              </td>
              <td>
                {{ item.customer ? item.customer.customer_code : "" }}
              </td>
              <td>
                {{ item.customer ? item.customer.customer_name : "" }}
              </td>
              <td>
                {{ setDateThLocale(item.createdAt) }}
              </td>
              <td>{{ formatPrice(item.discount) }}</td>

              <td>{{ formatPrice(item.final_price) }}</td>

              <td v-if="item.is_cancel === '0'">
                <div class="row">
                  <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-eye-fill"></i>
                    </button>
                  </div>

                  <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                    <button
                      class="btn btn-link btn-color-muted btn-active-color-danger m-0 p-0"
                      @click="handleCancelOrder(item)"
                      @mouseenter="handleHoverB()"
                      style="color: red; cursor: pointer"
                    >
                      <i class="bi bi-x-circle"></i>
                    </button>
                  </div>

                  <div
                    class="col-6 col-sm-3 col-md-3 col-lg-3"
                    @mousedown="hoverShortExportBtn()"
                  >
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToShortExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingShortExport
                            ? ''
                            : index == indexShortExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-receipt"></i>
                      </div>
                    </button>
                  </div>

                  <div
                    class="col-6 col-sm-3 col-md-3 col-lg-3"
                    @mousedown="hoverExportBtn()"
                  >
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>

              <td v-if="item.is_cancel === '1'">
                <div class="row">
                  <div
                    class="col-12 col-sm-6 col-md-6 col-lg-6"
                    style="cursor: ; color: FireBrick; font-weight: 500"
                  >
                    ยกเลิกแล้ว
                  </div>

                  <div
                    class="col-6 col-sm-3 col-md-3 col-lg-3"
                    @mousedown="hoverShortExportBtn()"
                  >
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToShortExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingShortExport
                            ? ''
                            : index == indexShortExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-receipt"></i>
                      </div>
                    </button>
                  </div>

                  <div
                    class="col-6 col-sm-3 col-md-3 col-lg-3"
                    @mousedown="hoverExportBtn()"
                  >
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-12 col-sm-4">
          <h4 class="mt-3">
            ยอดรวมทั้งหมด : <b>{{ total }}</b> บาท
          </h4>
        </div>
        <div class="col-12 col-sm-4 text-sm-center">
          <h4 class="mt-3">
            จำนวนบิลรวม :
            <b>{{ parseFloat(totalBill) }}</b> บิล
          </h4>
        </div>
        <div class="col-12 col-sm-4 text-sm-end">
          <h4 class="mt-3">
            ยกเลิกบิลรวม : <b>{{ totalCancelBill }}</b> บิล
          </h4>
        </div>
      </div>
    </div>

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div v-if="isShortExport">
      <shortPdfFile
        :dataShortExport="dataShortExport"
        @generatedShortFile="generatedShortFile"
      />
    </div>

    <DialogCancelOrder
      :dialogCancelOrder="dialogCancelOrder"
      @submitCancelOrder="submitCancelOrder"
      @closeDialogCancelOrder="closeDialogCancelOrder"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import saleApi from "@/api/sale/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../components/dialogConfirmByPass.vue";
import DialogCancelOrder from "./components/dialogCancelOrder.vue";
import Pagination from "../components/pagination.vue";
import Loader from "../components/loaderIndex.vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";

import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import numbro from "numbro";

import pdfFile from "../components/files/posFile.vue";

import shortPdfFile from "../components/files/shortPosFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    DialogCancelOrder,
    Pagination,
    Loader,
    pdfFile,
    shortPdfFile,
  },
  setup() {
    document.title = "BELUCA | สรุปขายปลีกประจำวัน";
    return {
      locale,
    };
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    dialogCancelOrder: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    isShortExport: false,
    loadingShortExport: false,
    indexShortExport: "",

    date: dayjs(new Date()),

    total: "",
    totalBill: "",
    totalCancelBill: "",

    tableItems: [],
    itemApprove: {},

    hoverA: false,
    hoverB: false,

    searchInput: "",
    dateSearch: "",
    allData: [],
  }),

  created() {
    this.search();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    sumTotal(items) {
      // sum all bill
      let sum = items.reduce(
        (sum, data) => sum + parseFloat(data.final_price),
        0
      );
      sum = sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      this.total = sum;

      // sum cancel bill
      // let filterBillCancel = items.filter((data) => data.is_cancel === "1");
      // let sumCancel = filterBillCancel.reduce(
      //   (sum, data, index) => sum + parseFloat(data.final_price),
      //   0
      // );

      let filterBill = items.filter((data) => data.is_cancel === "0");
      let sumBill = filterBill.length;

      this.totalBill = sumBill;

      let filterBillCancel = items.filter((data) => data.is_cancel === "1");
      let sumCancel = filterBillCancel.length;

      this.totalCancelBill = sumCancel;
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push("/sale/SA2-1/new");
    },
    goToEdit(id) {
      this.$router.push({
        path: "/sale/SA5",
        query: {
          id: id,
        },
      });
    },
    goToDelete() {},

    handleHoverA() {
      this.hoverA = true;
      this.hoverB = false;
    },
    handleHoverB() {
      this.hoverB = true;
      this.hoverA = false;
    },

    handleApprove() {
      this.dialogConfirmByPass = true;
    },

    handleCancelOrder(item) {
      this.itemApprove = { ...item };
      this.dialogCancelOrder = true;
    },

    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },

    submitCancelOrder(isApprove) {
      this.statusApprove(isApprove, "cancel");
      this.dialogCancelOrder = false;
    },

    closeDialogCancelOrder() {
      this.dialogCancelOrder = false;
    },

    formatDate(date) {
      const newDate = moment(date.substring(0, 10)).format("DD/MM/YYYY");
      const newTime = moment(date).format("LTS");
      if (date) {
        const oldDate = `${newDate} - ${newTime}`;

        return oldDate;
      } else {
        return "-";
      }
    },

    async statusApprove(isApprove, method) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
        is_cancel: method === "cancel" ? "1" : "0",
      };

      updateResponse = await saleApi.pos.update(id, { is_cancel: "1" });
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          this.updateProduct();
          Swal.fire({
            icon: `${method === "cancel" ? "error" : "success"}`,
            title: `${
              method === "cancel" ? "ยกเลิกรายการสำเร็จ" : "อนุมัติสำเร็จ"
            }`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.loadingUpdate = false;
            this.search();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.search();
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct() {
      const updateResponse = await whApi.product.multiUpdate(
        this.itemApprove.pointOfSaleItems,
        "posC"
      );

      if (updateResponse.response_status === "ERROR") {
        Swal.fire({
          icon: "error",
          html: `ปรับสต๊อกสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      }
      // let countArr = 0;
      // for (const element of this.itemApprove.pointOfSaleItems) {
      //   countArr += 1;
      //   await this.getOneProduct(element.product.id, element.item_amt);
      // }
    },
    async getOneProduct(id, item_amt) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(item_amt, item) {
      let updateResponse = [];
      let sumQty = parseFloat(item.product_bring_qty) + parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
        isUpdateQty: true,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    setDateThLocale(dateVal) {
      moment.locale("th");
      return moment(dateVal).format("DD/MM/YYYY - HH:mm:ss น.");
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;

      this.loadingShortExport = false;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },
    // Export file

    // Short export file
    goToShortExport(item, index) {
      this.dataShortExport = item;
      this.indexShortExport = index;
      this.loadingShortExport = true;
      this.isShortExport = true;

      this.loadingExport = false;
    },
    generatedShortFile() {
      this.isShortExport = false;
    },
    hoverShortExportBtn() {
      this.loadingShortExport = false;
      this.isShortExport = false;
    },
    // Short export file

    async search() {
      const newDate = this.date
        ? moment(this.date.$d).format("YYYY-MM-DD")
        : "";
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");
      this.loading = true;

      const responseSearchPos = await saleApi.pos.search(
        {
          search: this.searchInput,
          date: newDate,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
        }
      );

      // const responseSearch = await saleApi.salesOrder.search(
      //   {
      //     search: this.searchInput,
      //     date: newDate,
      //   },
      //   "1"
      // );

      if (
        responseSearchPos.response_status === "SUCCESS"
        // &&
        // responseSearch.response_status === "SUCCESS"
      ) {
        // this.dataItemsShow = [
        //   ...responseSearchPos.data,
        //   ...responseSearch.data,
        // ];
        // this.tableItems = [...responseSearchPos.data, ...responseSearch.data];
        // this.allData = [...responseSearchPos.data, ...responseSearch.data];
        this.dataItemsShow = responseSearchPos.data;
        this.tableItems = responseSearchPos.data;
        this.allData = responseSearchPos.data;
        this.loading = false;
        this.sumTotal(this.dataItemsShow);
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
