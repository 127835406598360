<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";
import apiConfig from "../../../config/api";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import numbro from "numbro";

export default {
  props: {
    dataShortExport: Object,
    type: Number,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",

    totalItem: 0,
    imgShow: "",
  }),
  async created() {
    console.log(this.dataShortExport);
    await this.getUser();
    await this.getCompanyBranch();
    await this.exports();
  },

  methods: {
    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getOne(userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
      }
    },

    async getCompanyBranch() {
      // const id = this.$route.query.id;

      const response = await userApi.companyBranch.getOne(
        this.dataShortExport.companyBranchId
      );

      if ((response.response_status = "SUCCESS")) {
        this.imgShow = response.data.file
          ? `${apiConfig.ENDPOINT}${response.data.file.path}`
          : "media/avatars/blank.png";

        console.log(this.imgShow);
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE128", displayValue: false });

      return canvas.toDataURL("image/png");
    },

    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = url;
      });
    },

    async exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      //setProduct Arr
      this.setProduct();

      // convert num to text TH
      this.convertNumberToTh();

      const html = [
        {
          image: this.imgShow
            ? await this.getBase64ImageFromURL(this.imgShow)
            : "",
          alignment: "center",
          width: 60,
          height: 60,
          margin: [0, -20, 0, 15],
        },
        {
          text: `${this.dataShortExport.company.company_name}`,
          alignment: "center",
          fontSize: 9,
        },
        {
          text: this.dataShortExport.companyBranch.company_branch_name
            ? this.dataShortExport.companyBranch.company_branch_name
            : this.dataShortExport.company.company_name,
          alignment: "center",
          fontSize: 9,
          margin: [0, 0, 0, 0],
        },
        this.dataShortExport.is_cancel === "1"
          ? {
              text: `- ยกเลิกแล้ว -`,
              alignment: "center",
              fontSize: 16,
              margin: [0, 10, 0, 10],
              bold: true,
              color: "#B22222",
            }
          : "",

        // LINE
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 295 - 2 * 40,
              y2: 5,
              lineWidth: 1,
              color: "#DCDCDC",
            },
          ],
          margin: [-35, 0, 0, 5],
        },
        // LINE

        {
          text: `ใบเสร็จรับเงิน`,
          alignment: "left",
          margin: [-35, 0, 0, 5],
          fontSize: 9,
        },
        {
          text: `( ${this.dataShortExport.point_of_sale_no} )`,
          alignment: "left",
          margin: [-35, 0, 0, 5],
          fontSize: 9,
        },

        // LINE
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 295 - 2 * 40,
              y2: 5,
              lineWidth: 1,
              color: "#DCDCDC",
            },
          ],
          margin: [-35, -5, 0, 5],
        },
        // LINE

        // TABLE
        {
          table: {
            widths: [25, 70, 61],
            body: this.newProduct,
          },
        },
        // TABLE

        // LINE DASH
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 295 - 2 * 40,
              y2: 5,
              dash: { length: 5, space: 3 },
            },
          ],
          margin: [-35, 0, 0, 0],
        },
        // LINE DASH

        {
          text: `จำนวนรวม : ${this.totalItem}`,
          fontSize: 9,
          margin: [-35, 5, 0, 5],
        },

        // TABLE
        {
          table: {
            widths: [100, 65],
            body: [
              [
                {
                  text: "รวมเป็นเงิน",
                  alignment: "right",
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [0, -5, 0, 5],
                },
                {
                  text: numbro(this.dataShortExport.final_price).format({
                    thousandSeparated: true,
                    mantissa: 2,
                  }),
                  alignment: "right",
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [10, -5, 0, 5],
                },
              ],
              [
                {
                  text: "ส่วนลด",
                  alignment: "right",
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [0, -5, 0, 5],
                },
                {
                  text: numbro(0).format({
                    thousandSeparated: true,
                    mantissa: 2,
                  }),
                  alignment: "right",
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [0, -5, 0, 5],
                },
              ],
              [
                {
                  text: "จำนวนเงินหลังหักส่วนลด",
                  alignment: "right",
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [0, -5, 0, 5],
                },
                {
                  text: numbro(this.dataShortExport.final_price).format({
                    thousandSeparated: true,
                    mantissa: 2,
                  }),
                  alignment: "right",
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [0, -5, 0, 5],
                },
              ],
              // [
              //   {
              //     text: "ภาษีมูลค่าเพิ่ม 7%",
              //     alignment: "right",
              //     border: [false, false, false, false],
              //     fontSize: 9,
              //     margin: [0, -5, 0, 5],
              //   },
              //   {
              //     text: numbro(this.dataShortExport.vat).format({
              //       thousandSeparated: true,
              //       mantissa: 2,
              //     }),
              //     alignment: "right",
              //     border: [false, false, false, false],
              //     fontSize: 9,
              //     margin: [0, -5, 0, 5],
              //   },
              // ],
              // [
              //   {
              //     text: "ราคาไม่รวมภาษีมูลค่าเพิ่ม",
              //     alignment: "right",
              //     border: [false, false, false, false],
              //     fontSize: 9,
              //     margin: [0, -5, 0, 5],
              //   },
              //   {
              //     text: numbro(
              //       this.dataShortExport.total_price_before_vat
              //     ).format({
              //       thousandSeparated: true,
              //       mantissa: 2,
              //     }),
              //     alignment: "right",
              //     border: [false, false, false, false],
              //     fontSize: 9,
              //     margin: [0, -5, 0, 5],
              //   },
              // ],
            ],
          },
        },
        // TABLE

        // LINE
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 246 - 2 * 40,
              y2: 5,
              lineWidth: 1,
              color: "#DCDCDC",
            },
          ],
          alignment: "right",
          margin: [0, 0, -17, 0],
        },
        // LINE

        {
          table: {
            widths: [100, 65],
            body: [
              [
                {
                  text: "รวมทั้งสิ้น",
                  alignment: "right",
                  border: [false, false, false, false],
                  bold: true,
                  fontSize: 11,
                },
                {
                  text: numbro(this.dataShortExport.final_price).format({
                    thousandSeparated: true,
                    mantissa: 2,
                  }),
                  alignment: "right",
                  border: [false, false, false, false],
                  bold: true,
                  fontSize: 11,
                },
              ],
            ],
          },
        },

        // LINE
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 246 - 2 * 40,
              y2: 5,
              lineWidth: 1,
            },
          ],
          margin: [0, -3, -17, 0],
          alignment: "right",
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 246 - 2 * 40,
              y2: 0,
              lineWidth: 1,
            },
          ],
          margin: [0, -3, -17, 0],
          alignment: "right",
        },
        // LINE

        // {
        //   text: "VAT INCLUDED",
        //   alignment: "center",
        //   margin: [0, 10, 0, 5],
        //   fontSize: 12,
        //   bold: true,
        // },

        // LINE DASH
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 295 - 2 * 40,
              y2: 5,
              dash: { length: 5, space: 3 },
            },
          ],
          margin: [-35, 10, 0, 0],
        },
        // LINE DASH
      ];

      const pdfData = {
        pageSize: {
          width: 225,
          height: "auto",
        },
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setProduct() {
      if (this.type === 1) {
        this.dataShortExport.pointOfSaleItems.forEach((element, index) => {
          this.totalItem += element.item_amt;

          this.newProduct.push([
            {
              text: element.item_amt,
              fontSize: 9,
              border: [false, false, false, false],
              margin: [-35, 0, 0, 0],
            },
            {
              text:
                element.product.product_name.length > 20
                  ? element.product.product_name.substring(0, 20) + "..."
                  : element.product.product_name,
              fontSize: 9,
              border: [false, false, false, false],
              margin: [-35, 0, 0, 0],
            },
            {
              text: numbro(
                element.item_promo_price
                  ? element.item_promo_price * element.item_amt
                  : element.item_price * element.item_amt
              ).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 9,
              alignment: "right",
              border: [false, false, false, false],
            },
          ]);
        });
      } else {
        this.dataShortExport.pointOfSaleItems.forEach((element, index) => {
          this.totalItem += element.item_amt;

          this.newProduct.push([
            {
              text: element.item_amt,
              fontSize: 9,
              border: [false, false, false, false],
              margin: [-35, 0, 0, 0],
            },
            {
              text:
                element.product.product_name.length > 20
                  ? element.product.product_name.substring(0, 20) + "..."
                  : element.product.product_name,
              fontSize: 9,
              border: [false, false, false, false],
              margin: [-35, 0, 0, 0],
            },
            {
              text: numbro(
                element.item_promo_price
                  ? element.item_promo_price * element.item_amt
                  : element.item_price * element.item_amt
              ).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 9,
              alignment: "right",
              border: [false, false, false, false],
            },
          ]);
        });
      }
    },

    convertNumberToTh() {
      this.numberToTh = ArabicNumberToText(this.dataShortExport.final_price);
    },
  },
};
</script>
